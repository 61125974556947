$grayFromXd: #f4f4f4;
$footerBg: #17223c;
$blueFromXd: #0a48ef;
$instructionRed: #e8404b;
$instructiongray: #f4f4f4;
$black: #17223c;
$yellow: #ffcc2f;
$blackShadow: #17223cb2;
$grayShadow: #00000019;
$grayLogo: #a0a0a0;

$bgGrayFromXd: #f9f9f9;
$whtteFromXd: #ffffff;

$red: #e8404b;
$footerColor: #76819b;

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap&subset=latin,latin-ext');

html {
  scroll-behavior: smooth;
}

.headerFont {
  font-family: 'Montserrat';
  font-weight: 1000;
}

.withoutBold {
  font-weight: 500;
}

.descFont {
  font-family: 'Lato';
}

body {
  @extend .descFont;
  letter-spacing: 0px;
  background-color: $bgGrayFromXd;
}

.red {
  color: $red;
}

.imgMaxWidth {
  width: 100%;
  vertical-align: baseline;
}

.imgSmallWidth {
  width: 70%;
}

.rpoLogo {
  width: 85%;
}

@media (min-width: 568px) {
  .imgFooterLogo {
    align-items: baseline;
    margin-left: 0;
    margin-right: auto;
  }
}

.toBootom {
  display: flex;
  align-items: flex-end;
}

.textJustify {
  text-align: justify;
}

.textAlignLeft {
  text-align: left;
}
.textAlignRight {
  text-align: right;
}
.textAlignCenter {
  text-align: center;
}

.justifyCenter {
  justify-content: center;
  display: flex;
}

.justifyLeft {
  justify-content: flex-start;
  display: flex;
}

.instructionIcon {
  width: 4em;
  display: flex;
  justify-content: center;
  margin-left: 0.5em;
  img {
    padding: 0.5em;
    height: 4em;
  }
}

.itmLogo {
  color: $grayLogo;
}

.customTooltip {
  width: 30em;
  .tooltip-inner {
    max-width: unset;
  }
}
.imgMainLogo {
  width: 11em;
}

.headerHr {
  margin-top: 0;
}
.imgHeaderRow {
  display: flex;
  align-items: center;
  background-color: white;
  padding-bottom: 1em;

  .imgHeaderCol {
    @extend .toBootom;

    @extend .justifyCenter;

    img {
      @extend .imgMainLogo;
    }

    &.logo {
      a {
        align-items: center;
        display: flex;
        img {
          width: none;
        }
      }
    }
  }
}

.bold {
  font-weight: bold;
}
.semiBold {
  font-weight: 700;
}

.upper {
  text-transform: uppercase;
}

.firstDesc {
  @extend .bold;
  @extend .upper;
}

footer {
  margin-top: 3em;
  background-color: $footerBg;
  width: 100%;
  color: white;
  @extend .headerFont;
  font-weight: SemiBold;
  font-size: 1em;

  .fotterMenu {
    font-size: 0.7em;
  }
  .partners {
    font-size: 0.7em;
  }
  .container {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  hr {
    border-color: white;
  }

  .link {
    color: $footerColor;
    &:hover {
      color: white;
    }
  }
}

.fillRow {
  flex: 1;
}

.descCollapse {
  align-self: end;
  border: none;
  background: none;
  color: $blueFromXd;
  font-family: Helvetica;
  font-size: 14px;
}

.oldCss {
  .questionOption {
    background-color: $grayFromXd;

    margin-bottom: 1em;
    .padBottom {
      padding-bottom: 1em;
    }
  }
}
.newCss {
  .questionOption {
    background-color: none;
    box-shadow: 1px 2px 6px $blackShadow;

    margin-bottom: 1em;
    .padBottom {
      padding-bottom: 1em;
    }
  }
}

.new2Css {
  .questionOption {
    background-color: $whtteFromXd;
    opacity: 1;
    border-radius: 1em;
    margin-bottom: 1em;
    &.checked {
      box-shadow: 0px 8px 24px $grayShadow;
      border: 1px solid #e2e2e2;
    }
    .padBottom {
      padding-bottom: 1em;
    }
  }
}

.itemsToCenter {
  align-items: center;

  display: flex;
}

.selfCenter {
  align-self: center;
}

.pad1 {
  padding: 1em;
  margin-bottom: 0;
}

.withoutShaddow {
  &.btn.btn-primary:focus,
  &.btn.btn-primary:active,
  &.btn.btn-primary:active:focus {
    box-shadow: none;
  }
  &:focus,
  &:active,
  &:active:focus {
    box-shadow: none;
    outline: none;
  }
}

.customRadio {
  & > button {
    background: none !important;
    border: none !important;
    height: 100%;
    @extend .withoutShaddow;

    .circle {
      width: 1.5em;
      height: 1.5em;
      background-color: white;
      border: 0.3em solid $red;
      flex: 1 1;
      border-color: $red;
      border-radius: 2em;
      &.checked {
        background-color: $red;
        //box-shadow: 0px 0px 6px $red;
      }
    }
  }
}

.spaceQuestions {
  margin-top: 2em;
}

.instructionOption {
  @extend .questionOption;

  .title {
    @extend .pad1;
    @extend .bold;
    @extend .upper;

    color: white;
  }

  button {
    color: white;
  }
  &.newCss {
    background-color: $instructionRed;
    border-radius: 0.3em;
    .content {
      padding: 0em 0.5em 0.5em 0.5em;
      .wrapper {
        padding: 0.5em;
        border-radius: 0.3em;
        background-color: $instructiongray;
      }
    }
  }

  &.new2Css {
    .instructionHeader.row {
      background-color: $instructionRed;
      border-radius: 0.3em;
    }
    box-shadow: 0px 3px 6px #00000029;

    .content {
      background-color: $whtteFromXd;
      padding: 0em 0.5em 0.5em 0.5em;
      border-bottom-left-radius: 0.3em;
      border-bottom-right-radius: 0.3em;
      .wrapper {
        padding: 0.5em;
        border-radius: 0.3em;
      }
    }
  }
}

.linkWithouDecoration {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.marginTop3em {
  margin-top: 3em;
}
.marginTop2em {
  margin-top: 2em;
}
.marginTop1em {
  margin-top: 1em;
}
.marginTop05em {
  margin-top: 0.5em;
}
.marginBottom05 {
  margin-bottom: 0.5em;
}

.container {
  & > .row {
    margin: inherit;
  }
}
.flex {
  display: flex;
  flex: 1;
}

.alignTop {
  display: flex;
  align-items: flex-start;
}

.banner112 {
  width: 21em;
  display: flex;
  font-family: 'Open Sans';
  letter-spacing: 0px;
  //border: solid 1px $black;
  //background-color: $yellow;
  padding: 0.5em;
  padding-top: 0;
  justify-content: center;
  flex: 1;
  align-items: center;
  margin-right: 0;
  margin-left: auto;

  .text {
    flex: 1;
    line-height: 1em;
    min-width: 22em;
    text-align: right;
    font-size: 0.8em;
    color: #dd3125;
  }

  .number {
    font-size: 1.5em;
    line-height: 1.4em;
    @extend .headerFont;
    margin-left: 0.4em;
    margin-right: 0.1em;
    .img {
      height: 2em;
    }
  }
}

.fotterDesc {
  color: $footerColor;
}

.aboutFirstPara {
  flex-direction: column;
}

@media (max-width: 600px) {
  .imgHeaderRow {
    .imgHeaderCol {
      justify-content: flex-start;
    }
  }

  .reverseOnMobile {
    flex-direction: column-reverse;
  }

  .banner112 {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    width: unset;
    flex: 1;
    .text {
      font-size: 0.7em;
    }
    .number {
      font-size: 1.6em;
    }
  }

  .footerLogo {
    margin-top: 1em;
  }

  .fotterMenu {
    padding-right: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .fotterDesc {
    padding-bottom: 1em;
  }

  .descCollapse {
    flex: 1;
  }
}
